<template>
  <div class="auth-page">
    <div class="no-connet" v-if="!web3Account">
      <h1>Blockchain Wallets</h1>
      <img src="../../assets/images/connect.png"/>
      <div class="sec">Connect a blockchain wallet and complete authentication. Please follow the tips on the page.</div>
      <div class="btn" @click="handleWalletConnect">Connect Your Wallet</div>
    </div>
    <div class="connect" v-if="web3Account && !personalSignAuth">
      <img src="../../assets/images/connectSuccess.png" class="con-success"/>
      <h2>Connected</h2>
      <span @click="disconnectWalletConnect">Disconnect</span>
      <p>Address</p>
      <h1>{{filterAddress(web3Account)}}</h1>
      <div class="btn" @click="personalSign">To Sign</div>
    </div>
    <div class="connect sign" v-if="personalSignAuth">
      <img src="../../assets/images/signSuccess.png" class="con-success"/>
      <h2>Signed</h2>
      <p>Address</p>
      <h1>{{filterAddress(web3Account)}}</h1>
      <div class="btn" @click="backHandle" v-show="callbackUrl">Back to Certification Center</div>
    </div>
  </div>
</template>
<script>
import Web3 from "web3";
import Web3Modal, { getInjectedProvider, getInjectedProviderName } from "web3modal";
import { providerOptions } from '@/web3/config';
import { getChainData } from '@/web3/tools';

let web3, web3Provider, web3Account, web3Chain, web3Modal;
export default {
  data() {
    return{
      web3,
      web3Provider,
      web3Account,
      web3Chain,
      personalSignAuth: false,
      connected: false,
      tenantId: '',
      tenantUserId: '',
      signMsg: 'test test test test<br/>' + new Date().getTime(),
      callbackUrl: ''
    }
  },
  created: async function() {
    Sensor.saElement('认证页' ,'进入认证页', 'enterChaincredit')
    web3Modal = new Web3Modal({
      theme: 'dark',
      network: getChainData(1).network,
      cacheProvider: true,
      providerOptions,
    });
  },
  mounted() {
    this.callbackUrl = this.$route.query.callbackUrl
  },
  methods: {
    async handleWalletConnect() {
      this.web3Provider = await web3Modal.connect();
      console.log(this.web3Provider)
      this.web3Provider.on('connect', async (chainId) => {
          this.web3Account = (await this.web3.eth.getAccounts())[0];
          this.connected = true
          this.web3Chain = chainId;
          console.log(this.web3Account)
      });
      this.web3Provider.on('accountsChanged', async (accounts) => {
        this.web3Account = accounts[0];
        // this.$emit('Web3AccountChange');
      });

      this.web3Provider.on('chainChanged', async (chainId) => {
        this.web3Account = (await this.web3.eth.getAccounts())[0];
        this.web3Chain = chainId;
      });

      this.web3Provider.on('disconnect', async () => {
        this.web3Account = undefined;
        this.web3Chain = undefined;
      });

      this.web3 = new Web3(this.web3Provider);
      this.web3Account = (await this.web3.eth.getAccounts())[0];
      this.web3Chain = await this.web3.eth.getChainId();
      console.log(this.web3)
    },
    async personalSign() {
      let res = await this.web3.eth.personal.sign(this.signMsg, this.web3Account, "")
      if (res) {
        this.personalSignAuth = true
        console.log('签名成功')
      }
    },
    async disconnectWalletConnect() {
      if(this.web3Provider && typeof this.web3Provider.close === "function"){
          await this.web3Provider.close()
      }
      web3Modal.clearCachedProvider()
      this.web3.eth.clearSubscriptions()
      this.web3Account  = undefined
      this.web3Chain = undefined
      this.web3Provider = undefined
      this.web3 = undefined
    },
    cacheWeb3Provider() {
      web3Modal.setCachedProvider();
    },
    clearWeb3Provider() {
      web3Modal.clearCachedProvider();
    },
    getInjectedWeb3Provider() {
      return getInjectedProvider();
    },
    getInjectedWeb3ProviderName() {
      return getInjectedProviderName();
    },
    filterAddress(val) {
      if (val) {
        return val.substring(0, 8) + '********' + val.substring(val.length - 8)
      }
      return ''
    },
    backHandle() {
      if (this.callbackUrl) {
        window.location = this.callbackUrl
      }
    }
  }
}
</script>
<style lang="scss">
.auth-page{
  padding: 30px 25px;
  background: #F9F9F9;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  img{
    width: 100px;
    height: 100px;
    display: block;
    margin: 25px auto;
    &.con-success{
      margin: 30px auto 0;
    }
  }
  h1{
    font-size: 22px;
    color: #100F1E; 
    text-align: center;
    font-weight: bold;
  }
  .sec{
    font-size: 15px;
    color: #505A6B;
    text-align: justify;
  }
  .btn{
    background: #4FBEA8;
    border-radius: 6px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 54px;
    margin-top: 28px;
    font-weight: bold;
  }
  .connect{
    text-align: center;
    h2{
      font-size: 16px;
      font-weight: bold;
      color: #505A6B;
      line-height: 30px;
    }
    p{
      font-size: 15px;
      color: #505A6B;
      margin-top: 50px;
      margin-bottom: 15px;
    }
    h1{
      font-size: 18px;
      color: #100F1E;
      font-weight: bold;
    }
    span{
      display: block;
      border: 0.5px solid #4FBEA8;
      border-radius: 6px;
      width: 114px;
      line-height: 30px;
      font-size: 14px;
      color: #4FBEA8;
      margin: 13px auto 0px;
    }
  }
}
</style>
